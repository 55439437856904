@charset "UTF-8";
/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* NAVIBAR SETTINGS OVERWRITED
-------------------------------------------------- */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
  .navbar.bg-light {
    background-color: #fff !important; }
  .navbar .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  .navbar.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }

.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: auto;
  white-space: nowrap;
  margin-top: 0;
  margin-left: 14px;
  height: 68px;
  min-width: 323px;
  text-indent: -99999px;
  background: url(../img/common/header_logo.png) 0 0 no-repeat;
  background-size: 323px auto; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 73px; }
  .navbar-nav .nav-link {
    padding: 0 1.25rem 28px;
    font-weight: bold; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-nav .nav-item {
    font-size: .9375rem;
    text-align: center;
    border-left: 2px dashed #f60; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  justify-content: flex-end !important;
  align-items: center;
  z-index: 50;
  background-color: #fff; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

/* TOGGLER ORIGINAL
------------------------------ */
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 3px solid transparent;
  border-radius: 0; }

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
      .navbar-expand-sm .navbar-nav .inverse-gray .nav-link {
        padding-right: 3rem;
        padding-left: 3rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
      .navbar-expand-md .navbar-nav .inverse-gray .nav-link {
        padding-right: 3rem;
        padding-left: 3rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
      .navbar-expand-lg .navbar-nav .inverse-gray .nav-link {
        padding-right: 3rem;
        padding-left: 3rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
      .navbar-expand-xl .navbar-nav .inverse-gray .nav-link {
        padding-right: 3rem;
        padding-left: 3rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1.5rem;
      padding-left: 1.5rem; }
    .navbar-expand .navbar-nav .inverse-gray .nav-link {
      padding-right: 3rem;
      padding-left: 3rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: #E78B31; }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #727059; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: #f58700;
  border-color: #f58700; }

.navbar-light .navbar-toggler-icon {
  background-image: url(../img/common/toggler-icon-or2.svg); }

.navbar-light .navbar-text {
  color: #E78B31; }

ul.navbar-sub {
  margin-bottom: 0;
  position: absolute;
  z-index: 150;
  top: 0;
  right: 0;
  display: flex;
  padding-left: 0;
  list-style: none; }
  ul.navbar-sub .sub-link {
    padding: 0 4rem;
    line-height: 41px;
    color: #fff; }
    ul.navbar-sub .sub-link:hover {
      text-decoration: none; }
  ul.navbar-sub .sub-item.si1 {
    background-color: #f58700; }
  ul.navbar-sub .sub-item.si1:hover {
    opacity: 0.7; }
  ul.navbar-sub .sub-item.si2 {
    background-color: #746a51; }
  ul.navbar-sub .sub-item.si2:hover {
    opacity: 0.7; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  background: transparent no-repeat center center; }

.carousel-control-prev-icon {
  opacity: 0.6;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  opacity: 0.6;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
  background-color: #EDE9E6; }

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  top: 13rem;
  z-index: 10; }
  .carousel-caption p {
    font-size: 1.5rem;
    text-align: center; }
    .carousel-caption p strong {
      font-weight: normal; }

/* Declare heights because of positioning of img element */
.carousel-item .slideimage img {
  max-width: 100%; }

.carousel-item > img {
  display: block;
  margin: 0 auto;
  max-width: 100%; }

.carousel-control-prev {
  left: 2rem; }

.carousel-control-next {
  right: 2rem; }

/* ORIGINAL COMMON SETTINGS
-------------------------------------------------- */
/* FIXED FOOTER MODULE
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
  z-index: 1; }

body {
  color: #000;
  /* Margin bottom by footer height */
  margin-bottom: 80px;
  padding-top: 124px; }

.text-s {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; }

.text-ss {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif; }

.bottom-links {
  padding-top: 74px;
  background: #fff url(../img/common/wave_gray.png) top center repeat-x; }
  .bottom-links .links-wrapper {
    background: #E8EDEB; }
  .bottom-links .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  .bottom-links .unit-title {
    color: #333;
    text-align: center;
    background: url(../img/common/bg_links_title.png) top center no-repeat; }
    .bottom-links .unit-title h2 {
      font-size: 1.25rem;
      padding-top: .5rem;
      font-weight: bold;
      padding-bottom: 2rem; }
  .bottom-links .links .link {
    padding-left: .5rem;
    padding-right: .5rem;
    margin-bottom: .5rem; }

.btn-btm-link {
  border-radius: .75rem;
  box-shadow: 1px 1px 3px -1px #b2b2b2;
  width: 100%;
  line-height: 70px;
  color: #f58700;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold; }
  .btn-btm-link img {
    margin-right: .5rem;
    margin-top: -4px; }
  .btn-btm-link:hover {
    color: #f58700;
    background-color: transparent;
    border-color: #f58700; }

.bottom-info {
  background-color: #E8EDEB;
  padding-top: 2.25rem;
  color: #333; }
  .bottom-info .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  .bottom-info .info-id .footer-id img {
    max-width: 100%; }
  .bottom-info .info-add a {
    color: #000 !important; }
    .bottom-info .info-add a:hover, .bottom-info .info-add a:focus {
      color: #000 !important; }
  .bottom-info .info-add p {
    margin-bottom: 0; }
  .bottom-info .info-add .add-name {
    font-size: .8125rem; }
  .bottom-info .info-add .add-tel span.tel {
    font-size: 1.875rem;
    font-weight: bold;
    margin-right: .75rem; }
  .bottom-info .info-add .add-tel span.fax {
    font-size: .9375rem; }
  .bottom-info .info-sns {
    padding-top: 2.25rem; }
    .bottom-info .info-sns ul {
      list-style: none;
      display: flex;
      justify-content: flex-end; }
      .bottom-info .info-sns ul a:hover img {
        opacity: 0.6; }
  .bottom-info .copyright .col-12 {
    text-align: center; }
    .bottom-info .copyright .col-12 p {
      font-size: .6875rem;
      margin-bottom: .5rem; }

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  /* Set the fixed height of the footer here */
  height: 80px;
  line-height: 80px;
  /* Vertically center the text there */
  background-color: #f58700;
  color: #fff; }
  .footer .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  .footer .col-11 .col-4 {
    text-align: center; }
    .footer .col-11 .col-4 span {
      line-height: 80px;
      display: inline-block;
      padding-left: 52px; }
    .footer .col-11 .col-4.link-faq span {
      background: url(../img/common/icon_question.png) 0 50% no-repeat; }
    .footer .col-11 .col-4.link-voice span {
      background: url(../img/common/icon_bubble.png) 0 50% no-repeat; }
    .footer .col-11 .col-4.link-consult span {
      background: url(../img/common/icon_env.png) 0 50% no-repeat; }
  .footer .col-1 {
    text-align: center; }
    .footer .col-1 a.pagetop {
      display: block;
      width: 30px;
      height: 29px;
      padding-top: 22px; }
  .footer a {
    color: #fff; }

.goto-link {
  color: #333; }
  .goto-link::before {
    content: ">>";
    font-size: .7rem;
    vertical-align: top; }
  .goto-link a {
    color: #333; }

/* CONTENTS BASE SETTIMGS
-------------------------------------------------- */
.body-wrapper {
  background-color: transparent; }

em,
strong {
  font-style: normal; }

.wp_social_bookmarking_light {
  display: flex; }

.btn-org1 {
  width: 320px;
  height: 80px;
  border-radius: 0;
  color: #fff;
  background-color: #c00;
  border-color: #fff;
  border-width: 3px; }
  .btn-org1:hover {
    color: #c00;
    background-color: transparent;
    border-color: #c00; }

.page-hero {
  background: #EDE9E6;
  margin-bottom: 1rem; }
  .page-hero .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  .page-hero .heroimage {
    margin-bottom: -79px; }
    .page-hero .heroimage img {
      max-width: 100%; }

.with-wave {
  max-width: 1200px;
  margin: 0 auto 3rem;
  padding-bottom: 90px;
  background: url(../img/common/wave_white_shadow.png) bottom center no-repeat; }

.page-title {
  background: #fff;
  border-radius: 1.5rem 1.5rem 0 0 / 1.5rem 1.5rem 0 0;
  width: 400px;
  height: 80px;
  margin: 0 auto;
  position: relative; }
  .page-title .title-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%; }
    .page-title .title-wrapper h2 {
      color: #333;
      font-size: 1.5rem;
      font-weight: bold;
      padding-bottom: .25rem;
      text-align: center; }

.sub-menus .container {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 42px;
  background: url(../img/common/wave_white_shadow.png) bottom center no-repeat;
  margin-bottom: 2.5rem; }

.sub-menus ul {
  padding-left: 0;
  list-style: none; }
  .sub-menus ul li {
    color: #f58700;
    font-size: .9375rem;
    font-weight: bold;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-right: 2px solid #f58700;
    white-space: no-wrap !important;
    display: inline; }
    .sub-menus ul li span {
      margin-right: 1rem; }
    .sub-menus ul li:last-of-type {
      border-right: none; }
    .sub-menus ul li a {
      color: #f58700; }

.page-lead {
  padding-bottom: 3.75rem; }
  .page-lead .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
    text-align: center; }

.top-lead {
  font-size: 1.3125rem; }

.top-lead p {
  font-size: 1.3125rem;
  margin-bottom: .15rem; }

.customer-cases .container {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0; }

.customer-cases .unit-title {
  color: #000;
  text-align: center; }
  .customer-cases .unit-title h2 {
    font-size: 1.375rem;
    font-weight: bold;
    padding-bottom: 2rem;
    background: url(../img/common/divider_or.png) bottom center no-repeat;
    margin-bottom: 4rem; }

.customer-cases .cases {
  margin-bottom: 5rem; }
  .customer-cases .cases .case {
    text-align: center; }
    .customer-cases .cases .case img {
      max-width: 100%;
      border-radius: 1rem;
      margin-bottom: .5rem;
      box-shadow: 1px 1px 3px -1px #b2b2b2; }
    .customer-cases .cases .case .case-meta {
      text-align: left;
      padding-left: 1.5rem;
      padding-right: 1.5rem; }
      .customer-cases .cases .case .case-meta h3 {
        font-size: 1rem;
        font-weight: bold;
        color: #000;
        margin-bottom: .25rem;
        padding-top: .75rem; }
        .customer-cases .cases .case .case-meta h3 a {
          color: #000; }
      .customer-cases .cases .case .case-meta p {
        color: #000;
        font-size: .8135rem;
        margin-bottom: 0; }
        .customer-cases .cases .case .case-meta p span {
          display: block; }
        .customer-cases .cases .case .case-meta p.post-link {
          padding-top: .25rem; }
          .customer-cases .cases .case .case-meta p.post-link a {
            color: #000; }

.btn-post-link {
  border-radius: .25rem;
  color: #000;
  background-color: #EBD9CA; }
  .btn-post-link:hover {
    color: #000;
    background-color: transparent;
    border-color: #000; }

/* CONTENT VIEWS
/* OVER WRITTEN STYLES
------------------------------ */
.customer-cases .pt-cv-wrapper h4.pt-cv-title {
  font-size: 1rem;
  margin-bottom: .25rem;
  padding-top: .75rem; }
  .customer-cases .pt-cv-wrapper h4.pt-cv-title a {
    color: #000; }
    .customer-cases .pt-cv-wrapper h4.pt-cv-title a:hover, .customer-cases .pt-cv-wrapper h4.pt-cv-title a:focus {
      text-decoration: underline !important; }

.customer-cases .pt-cv-wrapper .pt-cv-ifield .pt-cv-href-thumbnail.pt-cv-thumb-default:hover,
.customer-cases .pt-cv-wrapper .pt-cv-ifield .pt-cv-href-thumbnail.pt-cv-thumb-default:focus {
  opacity: 0.7; }

.customer-cases .pt-cv-wrapper .pt-cv-content {
  font-size: 1rem; }
  .customer-cases .pt-cv-wrapper .pt-cv-content a.pt-cv-readmore.pt-cv-textlink {
    text-decoration: underline !important;
    font-size: .875rem; }

.top-info .container {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0; }

.top-info .pt-cv-wrapper h4.pt-cv-title {
  font-size: 1rem;
  margin-bottom: .75rem; }
  .top-info .pt-cv-wrapper h4.pt-cv-title a {
    color: #fff; }
    .top-info .pt-cv-wrapper h4.pt-cv-title a:hover, .top-info .pt-cv-wrapper h4.pt-cv-title a:focus {
      text-decoration: underline !important; }

.top-info .pt-cv-wrapper .pt-cv-content {
  font-size: 1rem;
  line-height: 1.4;
  color: #fff; }
  .top-info .pt-cv-wrapper .pt-cv-content a.pt-cv-readmore.pt-cv-textlink {
    text-decoration: underline !important;
    font-size: .875rem; }

.top-info .pt-cv-wrapper .pt-cv-meta-fields {
  color: #fff; }
  .top-info .pt-cv-wrapper .pt-cv-meta-fields a,
  .top-info .pt-cv-wrapper .pt-cv-meta-fields time {
    color: #fff; }
  .top-info .pt-cv-wrapper .pt-cv-meta-fields span {
    color: #fff; }

.top-info .pt-cv-wrapper #pt-cv-view-5221913uoj {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0; }
  .top-info .pt-cv-wrapper #pt-cv-view-5221913uoj a.pt-cv-readmore:hover,
  .top-info .pt-cv-wrapper #pt-cv-view-5221913uoj a.pt-cv-readmore:focus {
    background-color: transparent !important; }

.top-blog .container {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0; }

.top-blog .pt-cv-wrapper h4.pt-cv-title {
  font-size: 1rem;
  margin-bottom: .75rem; }
  .top-blog .pt-cv-wrapper h4.pt-cv-title a {
    color: #000; }
    .top-blog .pt-cv-wrapper h4.pt-cv-title a:hover, .top-blog .pt-cv-wrapper h4.pt-cv-title a:focus {
      text-decoration: underline !important; }

.top-blog .pt-cv-wrapper .pt-cv-content {
  font-size: 1rem;
  line-height: 1.4;
  color: #000; }
  .top-blog .pt-cv-wrapper .pt-cv-content a.pt-cv-readmore.pt-cv-textlink {
    text-decoration: underline !important;
    font-size: .875rem; }

.top-blog .pt-cv-wrapper #pt-cv-view-e8d4245fng {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0; }
  .top-blog .pt-cv-wrapper #pt-cv-view-e8d4245fng a.pt-cv-readmore:hover,
  .top-blog .pt-cv-wrapper #pt-cv-view-e8d4245fng a.pt-cv-readmore:focus {
    background-color: #f58700 !important; }

/* UNIT
/* TOP WAVE GRAY / CONTENT GRAY
------------------------------ */
.unit.tgcg .container {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 100px;
  padding-bottom: 70px;
  background: #E8EDEB url(../img/common/wave_white_gray.png) center top no-repeat; }

/* UNIT
/* TOP WAVE WHITE / CONTENT WHITE
------------------------------ */
.unit.twcw .container {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 100px;
  padding-bottom: 70px;
  background: #fff url(../img/common/wave_gr_wh.png) center top no-repeat; }

/* UNIT
/* TOP WAVE NONE / CONTENT WHITE
------------------------------ */
.unit.tnocw .container {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 30px;
  padding-bottom: 70px;
  background: #fff; }

/* UNIT
/* TOP WAVE GRAY / CONTENT GRAY
------------------------------ */
.unit.toco {
  padding-top: 70px;
  background: url(../img/common/wave_wh_or.png) top center repeat-x; }
  .unit.toco .bg-wrapper {
    background: #f58700; }
  .unit.toco .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
    background: #f58700; }

/* UNIT
/* BALLOON LEFT
------------------------------ */
.info-balloon {
  max-width: 860px;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto; }
  .info-balloon .balloon1 {
    margin-left: 1rem; }
    .info-balloon .balloon1 span.dbl {
      display: block; }
  .info-balloon .info-caracter {
    padding-top: 1rem; }
    .info-balloon .info-caracter img {
      max-width: 100%; }

.info-balloon2 {
  max-width: 860px;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto; }
  .info-balloon2 .balloon1 {
    margin-left: 1rem; }
    .info-balloon2 .balloon1 span.dbl {
      display: block; }
  .info-balloon2 .info-caracter {
    padding-top: 2.5rem; }
    .info-balloon2 .info-caracter img {
      max-width: 100%; }

.balloon1 {
  position: relative;
  background: #F9D8B2;
  border-radius: .75rem; }
  .balloon1 .balloon-wrap {
    margin: 1.5rem 1rem 1.5rem 1.25rem; }
    .balloon1 .balloon-wrap p {
      margin-bottom: 0;
      color: #654B32;
      font-size: .9375rem;
      font-weight: bold; }
      .balloon1 .balloon-wrap p span {
        display: block; }
      .balloon1 .balloon-wrap p.info-tel {
        font-size: 1.625rem; }
      .balloon1 .balloon-wrap p.info-time span {
        padding-right: 1.25rem;
        display: inline; }
      .balloon1 .balloon-wrap p a {
        color: #f58700; }

.balloon1:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(249, 216, 178, 0);
  border-right-color: #F9D8B2;
  border-width: 16px 20px 16px 0;
  margin-top: -16px; }

.white-balloon {
  max-width: 860px;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto; }
  .white-balloon .balloon2 {
    margin-left: 1rem; }
    .white-balloon .balloon2 span.dbl {
      display: block; }
  .white-balloon .info-caracter {
    padding-top: 5rem; }
    .white-balloon .info-caracter img {
      max-width: 100%; }

.balloon2 {
  position: relative;
  background: #fff;
  border-radius: .75rem; }
  .balloon2 .balloon-wrap {
    margin: 1.5rem 1rem 1.5rem 1.25rem; }
    .balloon2 .balloon-wrap p {
      margin-bottom: 0;
      color: #333;
      font-size: .9375rem; }
      .balloon2 .balloon-wrap p.info-tel {
        font-size: 1.625rem; }
      .balloon2 .balloon-wrap p.info-time span {
        padding-right: 1.25rem; }
      .balloon2 .balloon-wrap p a {
        color: #f58700; }

.balloon2:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(249, 216, 178, 0);
  border-right-color: #fff;
  border-width: 16px 20px 16px 0;
  margin-top: -16px; }

.module-choices {
  background: url(../img/common/wave_orange.png) top center repeat-x, url(../img/common/wave_white_tr.png) bottom center repeat-x; }
  .module-choices .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 140px;
    padding-bottom: 140px;
    background: url(../img/common/wave_orange.png) top center no-repeat, url(../img/common/wave_white_tr.png) bottom center no-repeat, url(../img/top/choice_bkg.jpg) top center repeat-y; }
  .module-choices .unit-title {
    color: #000;
    text-align: center; }
    .module-choices .unit-title h2 {
      font-size: 1.375rem;
      font-weight: bold;
      padding-bottom: 2rem;
      background: url(../img/common/divider_or.png) bottom center no-repeat;
      margin-bottom: 2rem; }
  .module-choices .choices .choice {
    text-align: center; }
    .module-choices .choices .choice img {
      max-width: 100%;
      border: 12px solid #fff;
      border-radius: 1rem;
      margin-bottom: .5rem;
      box-shadow: 3px 3px 0 2px #CED3D0; }
    .module-choices .choices .choice h3 {
      font-size: 1.25rem;
      color: #F58700;
      margin-bottom: .25rem;
      padding-top: 1rem; }
      .module-choices .choices .choice h3 a {
        color: #F58700; }
        .module-choices .choices .choice h3 a:hover {
          text-decoration: none; }
    .module-choices .choices .choice p {
      color: #000;
      font-size: .875rem;
      margin-bottom: 0; }
      .module-choices .choices .choice p span {
        display: block; }

/* UNIT TITLE
/* BACKGROUND LINE
------------------------------ */
.unit-title.bg-line {
  text-align: center;
  margin-bottom: 1.5rem; }
  .unit-title.bg-line .title-wrapper {
    max-width: 1170px;
    margin-left: 15px;
    margin-right: 15px;
    background: url(../img/common/bg_unit_title.png) left 50% repeat-x; }
  .unit-title.bg-line h3 {
    font-size: 1.3125rem;
    font-weight: bold;
    display: block;
    margin-bottom: 0; }
    .unit-title.bg-line h3 span {
      background-color: #E8EDEB;
      padding-left: 2rem;
      padding-right: 2rem; }
  .unit-title.bg-line.bg-gr h3 span {
    background-color: #E8EDEB; }
  .unit-title.bg-line.bg-wh h3 span {
    background-color: #fff; }

/* LIST UNIT
/* BULLET-CHECK
------------------------------ */
.bullet-check {
  padding-left: 0;
  list-style: none;
  font-size: 1.0625rem; }
  .bullet-check li {
    padding-left: 2rem;
    background: url(../img/common/bullet_check.png) 4px 0 no-repeat;
    line-height: 21px;
    margin-bottom: 1rem; }

.bullet-check.type-faq {
  padding-left: 0;
  list-style: none;
  font-size: 1.0625rem; }
  .bullet-check.type-faq li {
    padding-left: 2rem;
    background: url(../img/common/bullet_check.png) 4px 0 no-repeat;
    line-height: 1.3125rem;
    margin-bottom: 1rem; }
    .bullet-check.type-faq li p {
      margin-bottom: .25rem; }
      .bullet-check.type-faq li p em {
        color: #f58700;
        font-size: 1.0625rem; }
      .bullet-check.type-faq li p.item-note {
        margin-bottom: 0; }
        .bullet-check.type-faq li p.item-note span {
          background: url(../img/common/highlight_y1.png) bottom left repeat-x; }

/* LIST UNIT
/* UNDERLINE-T1
------------------------------ */
.underline-t1 {
  padding-left: 0;
  width: 100%; }
  .underline-t1 li {
    list-style-type: disc;
    list-style-position: inside;
    border-bottom: 1px solid #f58700;
    line-height: 46px; }

/* LIST UNIT
/* STARS ★
------------------------------ */
.stars {
  padding-left: 0;
  list-style: none; }

/* UNIT TITLE W/ DIVIDER OR
------------------------------ */
.unit-title {
  text-align: center; }
  .unit-title.div-or h3 {
    font-size: 1.3125rem;
    font-weight: bold;
    padding-bottom: 2rem;
    background: url(../img/common/divider_or.png) bottom center no-repeat;
    margin-bottom: 2rem; }

/* UNIT LEAD
/* TYPE 1
------------------------------ */
.unit-lead-t1 {
  text-align: center; }
  .unit-lead-t1 p {
    line-height: 1.6;
    margin-bottom: 0; }
    .unit-lead-t1 p span {
      display: block; }

.bottom-note {
  text-align: center; }
  .bottom-note p {
    line-height: 1.6;
    margin-bottom: 0; }
    .bottom-note p span {
      display: block; }

p.mail-link {
  color: #f58700;
  padding-top: 2rem;
  text-align: center;
  font-size: 1.0625rem; }
  p.mail-link a {
    color: #f58700;
    text-decoration: underline; }

/* DEFINITION LIST UNIT
/* DETAIL LIST
------------------------------ */
dl.row.no-gutters.detail-list {
  max-width: 820px;
  margin: 0 auto; }
  dl.row.no-gutters.detail-list dt {
    color: #69694f;
    font-weight: normal;
    font-size: 1.25rem;
    text-align: right;
    border-right: 5px solid #69694f;
    margin-bottom: 2rem;
    padding-right: 1rem; }
  dl.row.no-gutters.detail-list dd {
    margin-bottom: 2rem;
    padding-left: 1rem;
    font-size: 1rem; }
    dl.row.no-gutters.detail-list dd ul {
      color: #69694f;
      padding-left: 0;
      margin-bottom: 0; }
      dl.row.no-gutters.detail-list dd ul li {
        padding-left: 0;
        list-style: none; }

/* BUTTON MODULES
------------------------------ */
.unit-button-t1 {
  text-align: center;
  padding-top: 2rem; }

.unit-button-t2 {
  text-align: right;
  margin-top: -44px; }

.unit-button-t3 {
  text-align: left;
  padding-top: 0; }

.btn-ilink {
  border-radius: .75rem;
  box-shadow: 2px 2px 0 1px #E6EBE9;
  max-width: 100%;
  line-height: 44px;
  color: #fff;
  background-color: #f58700;
  padding-top: 0;
  padding-bottom: 0;
  border-color: #f58700;
  border-width: 1px; }
  .btn-ilink:hover {
    color: #f58700;
    background-color: transparent;
    border-color: #f58700; }

.btn-plink {
  font-size: 1rem;
  border-radius: .75rem;
  box-shadow: 2px 2px 0 1px #E6EBE9;
  max-width: 100%;
  line-height: 44px;
  color: #fff;
  background-color: #f58700;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-color: #f58700;
  border-width: 1px; }
  .btn-plink:hover {
    color: #f58700;
    background-color: transparent;
    border-color: #f58700; }

.bt-wrap-t2 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: .5rem; }
  .bt-wrap-t2 a {
    font-size: 1rem; }

.bt-wrap-t3 {
  padding-left: 0;
  padding-right: 1.5rem;
  padding-bottom: .5rem; }
  .bt-wrap-t3 a {
    font-size: 1rem; }

.bt-wrap-t4 {
  padding-left: 1.5rem;
  padding-right: 0;
  padding-bottom: .5rem; }
  .bt-wrap-t4 a {
    font-size: 1rem; }

.btn-pdflink {
  font-style: 1rem;
  border-radius: .75rem;
  box-shadow: 2px 2px 0 1px #E6EBE9;
  width: 340px;
  line-height: 44px;
  color: #fff;
  background-color: #f58700;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border-color: #f58700;
  border-width: 1px; }
  .btn-pdflink:hover {
    color: #f58700;
    background-color: transparent;
    border-color: #f58700; }

.bt-wrap-t3 {
  width: 340px;
  padding-left: 0;
  padding-right: 0; }
  .bt-wrap-t3 a {
    font-size: 1rem; }

.btn-estlink {
  font-style: 1rem;
  border-radius: .75rem;
  box-shadow: 2px 2px 0 1px #D1DAD7;
  width: 200px;
  line-height: 44px;
  color: #fff;
  background-color: #f58700;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border-color: #f58700;
  border-width: 1px; }
  .btn-estlink:hover {
    color: #f58700;
    background-color: transparent;
    border-color: #f58700; }

dl.evenodd {
  max-width: 980px;
  margin: 0 auto 4rem; }
  dl.evenodd dt {
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
    padding-top: 1.25rem;
    padding-bottom: 1rem; }
    dl.evenodd dt span {
      display: block; }
    dl.evenodd dt:nth-of-type(2n) {
      background-color: #fff7f0;
      border-top: 1px solid #f58700;
      border-bottom: 1px solid #f58700; }
  dl.evenodd dd {
    font-size: 1rem;
    margin: 0;
    padding-top: 1.25rem;
    padding-bottom: 1rem; }
    dl.evenodd dd p {
      margin-bottom: .25rem;
      font-size: 1rem; }
      dl.evenodd dd p span {
        display: inline; }
    dl.evenodd dd:nth-of-type(2n) {
      background-color: #fff7f0;
      border-top: 1px solid #f58700;
      border-bottom: 1px solid #f58700; }

/* BREADCRUMBS MODULE
------------------------------ */
.breadcrumbs {
  background-color: transparent;
  padding-bottom: 3rem; }
  .breadcrumbs .breadcrumb {
    margin-bottom: 0;
    background-color: transparent; }

.breadcrumb-item {
  font-size: .875rem; }
  .breadcrumb-item a {
    color: #666; }
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">>"; }
  .breadcrumb-item.active {
    color: #666; }

/* RESPONSIVE CSS
-------------------------------------------------- */
@media (max-width: 1199px) {
  body {
    padding-top: 167px; }
  .navbar {
    padding-top: 0; }
    .navbar .navbar-brand {
      margin: 0 auto; }
    .navbar > .container {
      flex-direction: column; }
    .navbar .navbar-toggler {
      margin-right: 1rem; }
  .navbar-nav .nav-item {
    border-left: none; }
    .navbar-nav .nav-item .nav-link {
      padding: 1rem 1.25rem 1rem; }
  ul.navbar-sub {
    position: static;
    width: 100%; }
    ul.navbar-sub .sub-item {
      width: 50%; }
    ul.navbar-sub .sub-link {
      padding: 0 0;
      display: inline-block;
      text-align: center;
      width: 100%; }
  .sub-menus .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .sub-menus ul li {
    color: #f58700;
    font-size: .9375rem;
    font-weight: bold;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    white-space: no-wrap !important;
    display: inline; }
  .page-lead .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .customer-cases .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .customer-cases .cases .case {
    padding-left: .5rem;
    padding-right: .5rem; }
  .top-info .container,
  .top-blog .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .unit.tgcg .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .unit.twcw .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .unit.twcw .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .unit.tnocw .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .module-choices .choices .choice {
    padding-left: .5rem;
    padding-right: .5rem; }
  .bottom-info {
    padding-top: 1.75rem; }
    .bottom-info .container {
      max-width: 1200px;
      padding-left: 15px;
      padding-right: 15px; }
    .bottom-info .info-id {
      margin-bottom: 2rem; }
    .bottom-info .info-add {
      padding-left: 2rem; }
      .bottom-info .info-add .add-name {
        font-size: .8125rem; }
      .bottom-info .info-add .add-tel span.tel {
        margin-right: 0; }
      .bottom-info .info-add .add-tel span.fax {
        display: block; }
    .bottom-info .info-sns {
      padding-top: 1rem;
      padding-bottom: 1rem; }
      .bottom-info .info-sns ul {
        padding-left: 0;
        max-width: 140px;
        margin: 0 auto;
        justify-content: center; }
        .bottom-info .info-sns ul li {
          width: 35px;
          padding: 0 2px;
          display: inline-block; }
          .bottom-info .info-sns ul li img {
            width: 31px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .navbar #header-nav {
    min-height: 128px;
    width: 100%; }
    .navbar #header-nav .navbar-nav {
      width: 100%;
      flex-basis: 100%;
      justify-content: space-around; }
  .navbar .navbar-brand {
    position: absolute;
    top: 10px;
    left: 50%;
    right: 50%;
    margin-left: -158px;
    height: 68px;
    z-index: 800; }
  .navbar {
    padding-top: 0; }
  ul.navbar-sub {
    position: static;
    position: absolute;
    top: 127px;
    z-index: 1000;
    width: 100%; }
    ul.navbar-sub .sub-item {
      width: 50%; }
    ul.navbar-sub .sub-link {
      padding: 0 0;
      display: inline-block;
      text-align: center;
      width: 100%; } }

@media (max-width: 991px) {
  body {
    padding-top: 138px; }
  .navbar > .container {
    flex-direction: row; }
  .navbar .navbar-brand {
    margin-top: 10px;
    height: 88px; }
  .navbar .navbar-collapse .navbar-nav {
    margin-top: 0; }
    .navbar .navbar-collapse .navbar-nav .nav-link {
      padding: 0 1.25rem 1.25rem; }
  .customer-cases .cases .case .case-meta {
    padding-left: .25rem;
    padding-right: .25rem; }
  .btn-lg.btn-btm-link {
    line-height: 70px;
    font-size: 1.15rem; }
  dl.row.no-gutters.detail-list dt {
    text-align: left;
    border-right: none;
    border-left: 5px solid #69694f;
    margin-bottom: 1rem;
    padding-right: 0;
    padding-left: 1rem; }
  dl.row.no-gutters.detail-list dd {
    margin-bottom: 1rem;
    padding-left: 1.25rem;
    font-size: 1rem; }
    dl.row.no-gutters.detail-list dd ul {
      padding-left: 0;
      margin-bottom: 0; }
      dl.row.no-gutters.detail-list dd ul li {
        padding-left: 0;
        list-style: none; }
  .module-choices .unit-title h2 {
    font-size: 1.25rem; }
  .module-choices .choices .choice {
    padding-left: .25rem;
    padding-right: .25rem; }
    .module-choices .choices .choice h3 {
      font-size: 1rem;
      color: #F58700; }
    .module-choices .choices .choice p {
      font-size: .8125rem; }
      .module-choices .choices .choice p span {
        display: block; }
  .btn-pdflink {
    width: auto;
    margin: 0 auto;
    line-height: 44px;
    padding-left: 2rem;
    padding-right: 2rem; }
  .bt-wrap-t3 {
    margin: 0 auto;
    width: auto; } }

@media (max-width: 767px) {
  body {
    padding-top: 128px; }
  .navbar .navbar-brand {
    height: 78px;
    min-width: 250px;
    background: url(../img/common/header_logo.png) 0 5px no-repeat;
    background-size: 250px auto; }
  .carousel {
    margin-bottom: 2rem; }
  .carousel-item .slidecaption {
    display: none; }
    .carousel-item .slidecaption img {
      height: auto; }
  .page-hero .heroimage {
    margin-bottom: -39px; }
  .page-title {
    background: #fff;
    border-radius: 1rem 1rem 0 0 / 1rem 1rem 0 0;
    width: 240px;
    height: 40px; }
    .page-title .title-wrapper h2 {
      font-size: 1rem;
      padding-bottom: 0; }
  .bt-wrap-t2 {
    padding-left: .5rem;
    padding-right: .5rem; }
    .bt-wrap-t2 a {
      font-size: .875rem; }
  .btn-plink {
    font-size: .875rem;
    padding-left: 1rem;
    padding-right: 1rem; }
  .customer-cases .unit-title h2 {
    line-height: 1.4; }
    .customer-cases .unit-title h2 span {
      display: block; }
  .customer-cases .cases .case {
    margin-bottom: 2rem; }
    .customer-cases .cases .case .case-meta {
      padding-left: 1rem;
      padding-right: 1rem; }
      .customer-cases .cases .case .case-meta h3 {
        padding-top: .75rem; }
  .unit-title.bg-line {
    margin-bottom: 1rem; }
    .unit-title.bg-line .title-wrapper {
      max-width: 1170px;
      margin-left: 0;
      margin-right: 0; }
    .unit-title.bg-line h3 {
      font-size: 1.25rem; }
      .unit-title.bg-line h3 span {
        padding-left: 1rem;
        padding-right: 1rem; }
  .bottom-links .unit-title {
    background: url(../img/common/bg_links_title.png) top center no-repeat;
    background-size: 96%; }
  .btn-lg.btn-btm-link {
    line-height: 52px; }
    .btn-lg.btn-btm-link img {
      display: none; }
  .footer {
    font-size: .875rem; }
    .footer .col-11 .col-4 span {
      line-height: auto;
      display: inline;
      padding-left: 0; }
    .footer .col-11 .col-4.link-faq span {
      background: none; }
    .footer .col-11 .col-4.link-voice span {
      background: none; }
    .footer .col-11 .col-4.link-consult span {
      background: none; }
  .info-balloon .balloon1,
  .info-balloon2 .balloon1 {
    margin-left: 0; }
    .info-balloon .balloon1 p.info-time span,
    .info-balloon2 .balloon1 p.info-time span {
      display: block;
      margin-right: 0; }
  .balloon1:after {
    border: none; }
  .white-balloon .balloon2 {
    margin-left: 0; }
  .balloon2:after {
    border: none; }
  .module-choices .choices .choice {
    margin-bottom: 1.5rem; }
    .module-choices .choices .choice h3 {
      padding-top: .75rem; }
    .module-choices .choices .choice p {
      font-size: .875rem; }
  dl.evenodd {
    margin-bottom: 2.5rem; }
    dl.evenodd dt {
      font-weight: bold;
      padding-top: 1.25rem;
      padding-bottom: .25rem; }
      dl.evenodd dt span {
        display: inline; }
      dl.evenodd dt:nth-of-type(2n) {
        border-top: 1px solid #f58700;
        border-bottom: none; }
    dl.evenodd dd {
      font-size: .875rem;
      margin: 0;
      padding-top: 0;
      padding-bottom: 1rem; }
      dl.evenodd dd p {
        font-size: .875rem; }
        dl.evenodd dd p span {
          display: inline; }
      dl.evenodd dd:nth-of-type(2n) {
        border-top: none;
        border-bottom: 1px solid #f58700; } }
