/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";

/* NAVIBAR SETTINGS OVERWRITED
-------------------------------------------------- */

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap; // allow us to do the line break for collapsing content
	align-items: center;
	justify-content: space-between; // space out brand from logo
	// padding: $navbar-padding-y $navbar-padding-x;
	padding: 0 0;
	// padding-top: 41px;

	// Because flex properties aren't inherited, we need to redeclare these first
	// few properities so that content nested within behave properly.
	> .container,
	> .container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
&.bg-light {
	background-color: #fff !important;
}
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		position: relative;
	}
&.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
}


// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
	display: inline-block;
	padding-top: 0; //$navbar-brand-padding-y;
	padding-bottom: 0; //$navbar-brand-padding-y;
	margin-right: 0; //$navbar-padding-x;
	font-size: $navbar-brand-font-size;
	line-height: auto; //inherit;
	white-space: nowrap;

	@include hover-focus {
		text-decoration: none;
	}
	margin-top: 0;
	margin-left: 14px;
	height: 68px;
	min-width: 323px;
	text-indent: -99999px;
	background: url(../img/common/header_logo.png) 0 0 no-repeat; //original size
	background-size: 323px auto;
}


// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
	display: flex;
	flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	margin-top: 73px;

	.nav-link {
		// padding-right: 0;
		// padding-left: 0;
		padding: 0 1.25rem 28px;
		// line-height: 41px;
		// background: url(../img/bullet_nav.png) 4px 50% no-repeat;
		font-weight: bold;
	}

	.dropdown-menu {
		position: static;
		float: none;
	}
	.nav-item {
		font-size: .9375rem;
		text-align: center;
		// line-height: 90px;
		border-left: 2px dashed #f60;
	}
}


// Navbar text
//
//

.navbar-text {
	display: inline-block;
	padding-top: $nav-link-padding-y;
	padding-bottom: $nav-link-padding-y;
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orienation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
	flex-basis: 100%;
	// For always expanded or extra full navbars, ensure content aligns itself
	// properly vertically. Can be easily overridden with flex utilities.
	justify-content: flex-end !important;
	align-items: center;
	z-index: 50;
	background-color: #fff;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
	padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
	font-size: $navbar-toggler-font-size;
	line-height: 1;
	background: transparent; // remove default button style
	border: $border-width solid transparent; // remove default button style
	@include border-radius($navbar-toggler-border-radius);

	@include hover-focus {
		text-decoration: none;
	}
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}


/* TOGGLER ORIGINAL
------------------------------ */
.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background: transparent;
	border: 3px solid transparent;
	border-radius: 0; }
	.navbar-toggler:focus, .navbar-toggler:hover {
		text-decoration: none; }

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%; }




// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
	@each $breakpoint in map-keys($grid-breakpoints) {
		$next: breakpoint-next($breakpoint, $grid-breakpoints);
		$infix: breakpoint-infix($next, $grid-breakpoints);

		&#{$infix} {
			@include media-breakpoint-down($breakpoint) {
				> .container,
				> .container-fluid {
					padding-right: 0;
					padding-left: 0;
				}
			}

			@include media-breakpoint-up($next) {
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;

				.navbar-nav {
					flex-direction: row;

					.dropdown-menu {
						position: absolute;
					}

					.dropdown-menu-right {
						right: 0;
						left: auto; // Reset the default from `.dropdown-menu`
					}

					.nav-link {
						padding-right: 1.5rem; //.5rem;
						padding-left: 1.5rem; //.5rem;
					}
					.inverse-gray {
						.nav-link {
							padding-right: 3rem;
							padding-left: 3rem;
						}
					}
				}

				// For nesting containers, have to redeclare for alignment purposes
				> .container,
				> .container-fluid {
					flex-wrap: nowrap;
				}

				// scss-lint:disable ImportantRule
				.navbar-collapse {
					display: flex !important;
				}
				// scss-lint:enable ImportantRule

				.navbar-toggler {
					display: none;
				}
			}
		}
	}
}


// Dark links against a light background
.navbar-light {
	.navbar-brand {
		color: $navbar-light-active-color;

		@include hover-focus {
			color: $navbar-light-active-color;
		}
	}

	.navbar-nav {
		.nav-link {
			color: #E78B31;

			@include hover-focus {
				color: #727059;
			}

			&.disabled {
				color: $navbar-light-disabled-color;
			}
		}

		.show > .nav-link,
		.active > .nav-link,
		.nav-link.show,
		.nav-link.active {
			color: $navbar-light-active-color;
		}
	}

	.navbar-toggler {
		color: #f58700;
		border-color: #f58700;
	}

	.navbar-toggler-icon {
		background-image: url(../img/common/toggler-icon-or2.svg);
	}

	.navbar-text {
		color: #E78B31;
	}
}



ul.navbar-sub {
	margin-bottom: 0;
	position: absolute;
	z-index: 150;
	top: 0;
	right: 0;
	display: flex;
	padding-left: 0;
	list-style: none;
	.sub-link {
		padding: 0 4rem;
		line-height: 41px;
		color: #fff;
	&:hover {
		text-decoration: none;
	}
	}
	.sub-item {
	&.si1 {
		background-color: #f58700;
	}
	&.si1:hover {
		opacity: 0.7;
	}
	&.si2 {
		background-color: rgba(116,106,81,1);
	}
	&.si2:hover {
		opacity: 0.7;
	}
	}

}


// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	background: transparent no-repeat center center;
}
.carousel-control-prev-icon {
	opacity: 0.6;
	background-image: $carousel-control-prev-icon-bg;
}
.carousel-control-next-icon {
	opacity: 0.6;
	background-image: $carousel-control-next-icon-bg;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
.carousel {
	margin-bottom: 4rem;
	background-color: #EDE9E6;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
	// bottom: 3rem;
	top: 13rem;
	z-index: 10;
	p {
		font-size: 1.5rem;
		text-align: center;
		strong {
			font-weight: normal;
		}
	}
}

/* Declare heights because of positioning of img element */
.carousel-item {
	// height: 500px;
	.slideimage {
		img {
			max-width: 100%;
		}
	}
}
.carousel-item > img {
	display: block;
	margin: 0 auto;
	max-width: 100%;
	// height: 500px;
}

.carousel-control-prev {
	left: 2rem;
}
.carousel-control-next {
	right: 2rem;
}

/* ORIGINAL COMMON SETTINGS
-------------------------------------------------- */
/* FIXED FOOTER MODULE
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
	z-index: 1;
}
body {
	// background-color: #fff;
	color: #000;
	/* Margin bottom by footer height */
	margin-bottom: 80px;
	padding-top: 124px;
}

.text-s {
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.text-ss {
	font-family:-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}


.bottom-links {
	padding-top: 74px;
	background: #fff url(../img/common/wave_gray.png) top center repeat-x;
	.links-wrapper {
		background: #E8EDEB;
	}
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.unit-title {
		color: #333;
		text-align: center;
		background: url(../img/common/bg_links_title.png) top center no-repeat;
		h2 {
			// width: 413px;
			font-size: 1.25rem;
			padding-top: .5rem;
			font-weight: bold;
			padding-bottom: 2rem;
		}
	}
	.links {
		.link {
			padding-left: .5rem;
			padding-right: .5rem;
			margin-bottom: .5rem;
		}
	}
}

.btn-btm-link {
	border-radius: .75rem;
	box-shadow:1px 1px 3px -1px #b2b2b2;
	width: 100%;
	line-height: 70px;
	color: #f58700;
	background-color: #fff;
	padding-top: 0;
	padding-bottom: 0;
	// border-color: #f58700;
	// border-width: 1px;
	font-weight: bold;
	img {
		margin-right: .5rem;
		margin-top: -4px;
	}
&:hover {
	color: #f58700;
	background-color: transparent;
	border-color: #f58700;
}
}



.bottom-info {
	background-color: #E8EDEB;
	padding-top: 2.25rem;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	color: #333;
	.info-id {
		.footer-id {
			img {
				max-width: 100%;
			}
		}
	}
	.info-add {
		a {
			color: #000 !important;
		&:hover,
		&:focus {
			color: #000 !important;
		}
		}
		p {
			margin-bottom: 0;
		}
		.add-name {
			font-size: .8125rem;
		}
		.add-tel {
			span.tel {
				font-size: 1.875rem;
				font-weight: bold;
				margin-right: .75rem;
			}
			span.fax {
				font-size: .9375rem;
			}
		}
	}
	.info-sns {
		padding-top: 2.25rem;
		ul {
			list-style: none;
			display: flex;
			justify-content: flex-end;
			a {
			&:hover {
				img {
					opacity: 0.6;
				}
			}
			}
		}
	}
	.copyright {
		.col-12 {
			text-align: center;
			p {
				font-size: .6875rem;
				margin-bottom: .5rem;
			}
		}
	}
}

.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1000;
	/* Set the fixed height of the footer here */
	height: 80px;
	line-height: 80px; /* Vertically center the text there */
	background-color: #f58700;
	color: #fff;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.col-11 {
		.col-4 {
			text-align: center;
			span {
				line-height: 80px;
				display: inline-block;
				padding-left: 52px;
			}
		&.link-faq {
			span {
				background: url(../img/common/icon_question.png) 0 50% no-repeat;
			}
		}
		&.link-voice {
			span {
				background: url(../img/common/icon_bubble.png) 0 50% no-repeat;
			}
		}
		&.link-consult {
			span {
				background: url(../img/common/icon_env.png) 0 50% no-repeat;
			}
		}
		}
	}
	.col-1 {
		text-align: center;
		a.pagetop {
			display: block;
			width: 30px;
			height: 29px;
			padding-top: 22px;
		}
	}
	a {
		color: #fff;
	}
}


.goto-link {
	color: #333;
	&::before {
		content: ">>";
		font-size: .7rem;
		vertical-align: top;
	}
	a {
		color: #333;
	}
}


/* CONTENTS BASE SETTIMGS
-------------------------------------------------- */
.body-wrapper {
	background-color: transparent;
}

.contents {
	// min-height: 1000px;
	// padding-bottom: 3rem;
}

em,
strong {
	font-style: normal;
}

.wp_social_bookmarking_light {
	display: flex;
	// justify-content: flex-end;
}

.btn-org1 {
	width: 320px;
	height: 80px;
	border-radius: 0;
	color: #fff;
	background-color: #c00;
	border-color: #fff;
	border-width: 3px;
&:hover {
	color: #c00;
	background-color: transparent;
	border-color: #c00;
}
}

.page-hero {
	background: #EDE9E6;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.heroimage {
		margin-bottom: -79px;
		img {
			max-width: 100%;
		}
	}
	margin-bottom: 1rem;
}

.with-wave {
	max-width: 1200px;
	margin: 0 auto 3rem;;
	padding-bottom: 90px;
	background: url(../img/common/wave_white_shadow.png) bottom center no-repeat;
}

.page-title {
	background: #fff;
	border-radius: 1.5rem 1.5rem 0 0 / 1.5rem 1.5rem 0 0;
	width: 400px;
	height: 80px;
	margin: 0 auto;
	position: relative;
	.title-wrapper {
		position: absolute;
		bottom: 0;
		width: 100%;
		h2 {
			color: #333;
			font-size: 1.5rem;
			font-weight: bold;
			padding-bottom: .25rem;
			text-align: center;
		}
	}
}

.sub-menus {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 42px;
		background: url(../img/common/wave_white_shadow.png) bottom center no-repeat;
		margin-bottom: 2.5rem;
	}
	ul {
		padding-left: 0;
		list-style: none;
		li {
			color: #f58700;
			font-size: .9375rem;
			font-weight: bold;
			padding-left: 1.25rem;
			padding-right: 1.25rem;
			border-right: 2px solid #f58700;
			white-space: no-wrap !important;
			display: inline;
			span {
				margin-right: 1rem;
			}
		&:first-of-type {
			// padding-left: 0;
		}
		&:last-of-type {
			border-right: none;
			// padding-right: 0;
		}
			a {
				color: #f58700;
			}
		}
	}
}

.page-lead {
	padding-bottom: 3.75rem;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
	}
}

.top-lead {
	font-size: 1.3125rem;
}

.top-lead {
	p {
		font-size: 1.3125rem;
		margin-bottom: .15rem;
	}
}

.customer-cases {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
	}
	.unit-title {
		color: #000;
		text-align: center;
		h2 {
			font-size: 1.375rem;
			font-weight: bold;
			padding-bottom: 2rem;
			background: url(../img/common/divider_or.png) bottom center no-repeat;
			margin-bottom: 4rem;
		}
	}
	.cases {
		margin-bottom: 5rem;
		.case {
			text-align: center;
			img {
				max-width: 100%;
				border-radius: 1rem;
				margin-bottom: .5rem;
				box-shadow:1px 1px 3px -1px #b2b2b2;
			}
			.case-meta {
				text-align: left;
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				h3 {
					font-size: 1rem;
					font-weight: bold;
					color: #000;
					margin-bottom: .25rem;
					padding-top: .75rem;
					a {
						color: #000;
					}
				}
				p {
					color: #000;
					font-size: .8135rem;
					margin-bottom: 0;
					span {
						display: block;
					}
				&.post-link {
					padding-top: .25rem;
					a {
						color: #000;
					}
				}
				}
			}
		}
	}
}

.btn-post-link {
	border-radius: .25rem;
	color: #000;
	background-color: #EBD9CA;
	// border-color: #000;
	// border-width: 2px;
&:hover {
	color: #000;
	background-color: transparent;
	border-color: #000;
}
}

/* CONTENT VIEWS
/* OVER WRITTEN STYLES
------------------------------ */
.customer-cases {
	.pt-cv-wrapper {

		h4.pt-cv-title {
			font-size: 1rem;
			margin-bottom: .25rem;
			padding-top: .75rem;
			// font-weight: normal;
			a {
				color: #000;
			&:hover,
			&:focus {
				text-decoration: underline !important;
			}
			}
		}
		.pt-cv-ifield {
			.pt-cv-href-thumbnail.pt-cv-thumb-default:hover,
			.pt-cv-href-thumbnail.pt-cv-thumb-default:focus {
				opacity: 0.7;
			}
		}
		.pt-cv-content {
			font-size: 1rem;
			a.pt-cv-readmore.pt-cv-textlink {
				text-decoration: underline !important;
				font-size: .875rem;
			}
		}
	}
}

.top-info {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.pt-cv-wrapper {
		h4.pt-cv-title {
			font-size: 1rem;
			margin-bottom: .75rem;
			// padding-top: .75rem;
			// font-weight: normal;
			a {
				color: #fff;
			&:hover,
			&:focus {
				text-decoration: underline !important;
			}
			}
		}
		.pt-cv-content {
			font-size: 1rem;
			line-height: 1.4;
			color: #fff;
			a.pt-cv-readmore.pt-cv-textlink {
				text-decoration: underline !important;
				font-size: .875rem;
			}
		}
		.pt-cv-meta-fields {
			color: #fff;
			a,
			time {
				color: #fff;
			}
			span {
				color: #fff;
			}
		}
		#pt-cv-view-5221913uoj {
			max-width: 1200px;
			padding-left: 0;
			padding-right: 0;
		 a.pt-cv-readmore:hover,
		 a.pt-cv-readmore:focus {
				background-color: transparent !important;
			}
		}
	}
}

.top-blog {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.pt-cv-wrapper {
		h4.pt-cv-title {
			font-size: 1rem;
			margin-bottom: .75rem;
			// padding-top: .75rem;
			// font-weight: normal;
			a {
				color: #000;
			&:hover,
			&:focus {
				text-decoration: underline !important;
			}
			}
		}
		.pt-cv-content {
			font-size: 1rem;
			line-height: 1.4;
			color: #000;
			a.pt-cv-readmore.pt-cv-textlink {
				text-decoration: underline !important;
				font-size: .875rem;
			}
		}
		#pt-cv-view-e8d4245fng {
			max-width: 1200px;
			padding-left: 0;
			padding-right: 0;
		 a.pt-cv-readmore:hover,
		 a.pt-cv-readmore:focus {
				background-color: #f58700 !important;
			}
		}
	}
}



/* UNIT
/* TOP WAVE GRAY / CONTENT GRAY
------------------------------ */
.unit.tgcg {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 100px;
		padding-bottom: 70px;
		background: #E8EDEB url(../img/common/wave_white_gray.png) center top no-repeat;
	}
}


/* UNIT
/* TOP WAVE WHITE / CONTENT WHITE
------------------------------ */
.unit.twcw {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 100px;
		padding-bottom: 70px;
		background: #fff url(../img/common/wave_gr_wh.png) center top no-repeat;
	}
}

/* UNIT
/* TOP WAVE NONE / CONTENT WHITE
------------------------------ */
.unit.tnocw {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 30px;
		padding-bottom: 70px;
		background: #fff;
	}
}

/* UNIT
/* TOP WAVE GRAY / CONTENT GRAY
------------------------------ */
.unit.toco {
	.bg-wrapper {
		background: #f58700;
	}
	padding-top: 70px;
	background: url(../img/common/wave_wh_or.png) top center repeat-x;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		background: #f58700;
	}
}

/* UNIT
/* BALLOON LEFT
------------------------------ */
.info-balloon {
	max-width: 860px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 auto;
	.balloon1 {
		margin-left: 1rem;
		span.dbl {
			display: block;
		}
	}
	.info-caracter {
		padding-top: 1rem;
		img {
			max-width: 100%;
		}
	}
}

.info-balloon2 {
	max-width: 860px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 auto;
	.balloon1 {
		margin-left: 1rem;
		span.dbl {
			display: block;
		}
	}
	.info-caracter {
		padding-top: 2.5rem;
		img {
			max-width: 100%;
		}
	}
}

.balloon1 {
	position: relative;
	background: #F9D8B2;
	border-radius: .75rem;
	.balloon-wrap {
		margin: 1.5rem 1rem 1.5rem 1.25rem;
		p {
			margin-bottom: 0;
			color: #654B32;
			font-size: .9375rem;
			font-weight: bold;
			span {
				display: block;
			}
		&.info-tel {
			font-size: 1.625rem;
		}
		&.info-time {
			span {
				padding-right: 1.25rem;
				display: inline;
			}
		}
		a {
			color: #f58700;
		}
		}
	}
}

.balloon1:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(249, 216, 178, 0);
	border-right-color: #F9D8B2;
	border-width: 16px 20px 16px 0;
	margin-top: -16px;
}

.white-balloon {
	max-width: 860px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 auto;
	.balloon2 {
		margin-left: 1rem;
		span.dbl {
			display: block;
		}
	}
	.info-caracter {
		padding-top: 5rem;
		img {
			max-width: 100%;
		}
	}
}

.balloon2 {
	position: relative;
	background: #fff;
	border-radius: .75rem;
	.balloon-wrap {
		margin: 1.5rem 1rem 1.5rem 1.25rem;
		p {
			margin-bottom: 0;
			color: #333;
			font-size: .9375rem;
		&.info-tel {
			font-size: 1.625rem;
		}
		&.info-time {
			span {
				padding-right: 1.25rem;
			}
		}
		a {
			color: #f58700;
		}
		}
	}
}

.balloon2:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(249, 216, 178, 0);
	border-right-color: #fff;
	border-width: 16px 20px 16px 0;
	margin-top: -16px;
}




.module-choices {
	background: url(../img/common/wave_orange.png) top center repeat-x, url(../img/common/wave_white_tr.png) bottom center repeat-x;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 140px;
		padding-bottom: 140px;
		background: url(../img/common/wave_orange.png) top center no-repeat, url(../img/common/wave_white_tr.png) bottom center no-repeat, url(../img/top/choice_bkg.jpg) top center repeat-y;
	}
	.unit-title {
		color: #000;
		text-align: center;
		h2 {
			font-size: 1.375rem;
			font-weight: bold;
			padding-bottom: 2rem;
			background: url(../img/common/divider_or.png) bottom center no-repeat;
			margin-bottom: 2rem;
		}
	}
	.choices {
		.choice {
			text-align: center;
			img {
				max-width: 100%;
				border: 12px solid #fff;
				border-radius: 1rem;
				margin-bottom: .5rem;
				// box-shadow: 3px 3px 9px -1px #b2b2b2;
				box-shadow: 3px 3px 0 2px #CED3D0;
			}
		h3 {
			font-size: 1.25rem;
			color: #F58700;
			margin-bottom: .25rem;
			padding-top: 1rem;
			a {
				color: #F58700;
			&:hover {
				text-decoration: none;
			}
			}
		}
		p {
			color: #000;
			font-size: .875rem;
			margin-bottom: 0;
			span {
				display: block;
			}
		}
		}
	}
}



/* UNIT TITLE
/* BACKGROUND LINE
------------------------------ */
.unit-title.bg-line {
	text-align: center;
	margin-bottom: 1.5rem;
	.title-wrapper {
		max-width: 1170px;
		margin-left: 15px;
		margin-right: 15px;
		background: url(../img/common/bg_unit_title.png) left 50% repeat-x;
	}
	h3 {
		font-size: 1.3125rem;
		font-weight: bold;
		display: block;
		margin-bottom: 0;
		span {
			background-color: #E8EDEB;
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
&.bg-gr {
	h3 {
		span {
			background-color: #E8EDEB;
		}
	}
}
&.bg-wh {
	h3 {
		span {
			background-color: #fff;
		}
	}
}
}


/* LIST UNIT
/* BULLET-CHECK
------------------------------ */
.bullet-check {
	padding-left: 0;
	list-style: none;
	font-size: 1.0625rem;
	li {
		padding-left: 2rem;
		background: url(../img/common/bullet_check.png) 4px 0 no-repeat;
		line-height: 21px;
		margin-bottom: 1rem;
	}
}

.bullet-check.type-faq {
	padding-left: 0;
	list-style: none;
	font-size: 1.0625rem;
	li {
		padding-left: 2rem;
		background: url(../img/common/bullet_check.png) 4px 0 no-repeat;
		line-height: 1.3125rem;
		margin-bottom: 1rem;
		p {
			margin-bottom: .25rem;
			em {
				color: #f58700;
				font-size: 1.0625rem;
			}
		&.item-note {
			margin-bottom: 0;
			span {
				background: url(../img/common/highlight_y1.png) bottom left repeat-x;
			}
		}
		}
	}
}

/* LIST UNIT
/* UNDERLINE-T1
------------------------------ */
.underline-t1 {
	padding-left: 0;
	width: 100%;
	li {
		list-style-type: disc;
		list-style-position: inside;
		border-bottom: 1px solid #f58700;
		line-height: 46px;
	}
}

/* LIST UNIT
/* STARS ★
------------------------------ */
.stars {
	padding-left: 0;
	list-style: none;
}


/* UNIT TITLE W/ DIVIDER OR
------------------------------ */
.unit-title {
	text-align: center;
&.div-or {
		h3 {
		font-size: 1.3125rem;
		font-weight: bold;
		padding-bottom: 2rem;
		background: url(../img/common/divider_or.png) bottom center no-repeat;
		margin-bottom: 2rem;
		}
	}
}


/* UNIT LEAD
/* TYPE 1
------------------------------ */
.unit-lead-t1 {
	text-align: center;
	p {
		line-height: 1.6;
		margin-bottom: 0;
		span {
			display: block;
		}
	}
}

.bottom-note {
	text-align: center;
	p {
		line-height: 1.6;
		margin-bottom: 0;
		span {
			display: block;
		}
	}
}


p.mail-link  {
	color: #f58700;
	padding-top: 2rem;
	text-align: center;
	font-size: 1.0625rem;
	a {
		color: #f58700;
		text-decoration: underline;
	}
}

/* DEFINITION LIST UNIT
/* DETAIL LIST
------------------------------ */
dl.row.no-gutters.detail-list {
	max-width: 820px;
	margin: 0 auto;
	dt {
		color: #69694f;
		font-weight: normal;
		font-size: 1.25rem;
		text-align: right;
		border-right: 5px solid #69694f;
		margin-bottom: 2rem;
		padding-right: 1rem;
	}
	dd {
		margin-bottom: 2rem;
		padding-left: 1rem;
		font-size: 1rem;
		ul {
			color: #69694f;
			padding-left: 0;
			margin-bottom: 0;
			li {
				padding-left: 0;
				list-style: none;
			}
		}
	}
}

/* BUTTON MODULES
------------------------------ */
.unit-button-t1 {
	text-align: center;
	padding-top: 2rem;
}

.unit-button-t2 {
	text-align: right;
	margin-top: -44px;
}

.unit-button-t3 {
	text-align: left;
	padding-top: 0;
}

.btn-ilink {
	border-radius: .75rem;
	// box-shadow:1px 1px 3px -1px #b2b2b2;
	box-shadow: 2px 2px 0 1px #E6EBE9;
	max-width: 100%;
	line-height: 44px;
	color: #fff;
	background-color: #f58700;
	padding-top: 0;
	padding-bottom: 0;
	border-color: #f58700;
	border-width: 1px;
&:hover {
	color: #f58700;
	background-color: transparent;
	border-color: #f58700;
}
}

.btn-plink {
	font-size: 1rem;
	border-radius: .75rem;
	// box-shadow:1px 1px 3px -1px #b2b2b2;
	box-shadow: 2px 2px 0 1px #E6EBE9;
	max-width: 100%;
	line-height: 44px;
	color: #fff;
	background-color: #f58700;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	border-color: #f58700;
	border-width: 1px;
&:hover {
	color: #f58700;
	background-color: transparent;
	border-color: #f58700;
}
}

.bt-wrap-t2 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: .5rem;
	a {
		font-size: 1rem;
	}
}

.bt-wrap-t3 {
	padding-left: 0;
	padding-right: 1.5rem;
	padding-bottom: .5rem;
	a {
		font-size: 1rem;
	}
}

.bt-wrap-t4 {
	padding-left: 1.5rem;
	padding-right: 0;
	padding-bottom: .5rem;
	a {
		font-size: 1rem;
	}
}

.btn-pdflink {
	font-style: 1rem;
	border-radius: .75rem;
	// box-shadow:1px 1px 3px -1px #b2b2b2;
	box-shadow: 2px 2px 0 1px #E6EBE9;
	width: 340px;
	line-height: 44px;
	color: #fff;
	background-color: #f58700;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	border-color: #f58700;
	border-width: 1px;
&:hover {
	color: #f58700;
	background-color: transparent;
	border-color: #f58700;
}
}

.bt-wrap-t3 {
	width: 340px;
	padding-left: 0;
	padding-right: 0;
	a {
		font-size: 1rem;
	}
}

.btn-estlink {
	font-style: 1rem;
	border-radius: .75rem;
	// box-shadow:1px 1px 3px -1px #b2b2b2;
	box-shadow: 2px 2px 0 1px #D1DAD7;
	width: 200px;
	line-height: 44px;
	color: #fff;
	background-color: #f58700;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	border-color: #f58700;
	border-width: 1px;
&:hover {
	color: #f58700;
	background-color: transparent;
	border-color: #f58700;
}
}

dl.evenodd {
	max-width: 980px;
	margin: 0 auto 4rem;
	dt {
		font-weight: normal;
		font-size: 1rem;
		margin: 0;
		padding-top: 1.25rem;
		padding-bottom: 1rem;
		span {
			display: block;
		}
	&:nth-of-type(2n) {
		background-color: #fff7f0;
		border-top: 1px solid #f58700;
		border-bottom: 1px solid #f58700;
	}
	}
	dd {
		font-size: 1rem;
		margin: 0;
		padding-top: 1.25rem;
		padding-bottom: 1rem;
		p {
			margin-bottom: .25rem;
			font-size: 1rem;
			span {
					display: inline;
			}
		}
	&:nth-of-type(2n) {
		background-color: #fff7f0;
		border-top: 1px solid #f58700;
		border-bottom: 1px solid #f58700;
	}
	}
}


/* BREADCRUMBS MODULE
------------------------------ */
.breadcrumbs {
	background-color: transparent;
	padding-bottom: 3rem;
	.breadcrumb {
		margin-bottom: 0;
		background-color: transparent;
	}
}

.breadcrumb-item {
	font-size: .875rem;
	a {
		color: #666;
	}
	+ .breadcrumb-item::before {
		content: ">>";
	}
	&.active {
		color: #666;
	}
}




/* RESPONSIVE CSS
-------------------------------------------------- */
@media (min-width: 1200px) and (max-width: 1240px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Medium devices (tablets, less than 1200px)
@media (max-width: 1199px) {
	body {
		padding-top: 167px;
	}
	.navbar {
		padding-top: 0;
		.navbar-brand {
			margin: 0 auto;
		}
		> .container {
			flex-direction: column;
		}
		.navbar-toggler {
				margin-right: 1rem;
		}
	}
	.navbar-collapse {
		// margin-bottom: -24px;
	}
	.navbar-nav {
		.nav-item {
			border-left: none;
			.nav-link {
				padding: 1rem 1.25rem 1rem;
			}
		}
	}
	ul.navbar-sub {
		position: static;
		// top: 74px;
		// left: 0;
		// right: auto;
		// z-index: 1;
		width: 100%;
		.sub-item {
			width: 50%;
		}
		.sub-link {
			padding: 0 0;
			display: inline-block;
			text-align: center;
			width: 100%;
		}
	}

	.sub-menus {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
		ul {
			li {
				color: #f58700;
				font-size: .9375rem;
				font-weight: bold;
				padding-left: 1.25rem;
				padding-right: 1.25rem;
				white-space: no-wrap !important;
				display: inline;
			}
		}
	}

	.page-lead {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.customer-cases {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
		.cases {
			.case {
				padding-left: .5rem;
				padding-right: .5rem;
			}
		}
	}

	.top-info,
	.top-blog {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.unit.tgcg {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.unit.twcw {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.unit.twcw {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.unit.tnocw {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.module-choices {
		.choices {
			.choice {
				padding-left: .5rem;
				padding-right: .5rem;
				img {

				}
			}
		}
	}


	.bottom-info {
		padding-top: 1.75rem;
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
		.info-id {
			margin-bottom: 2rem;
		}
		.info-add {
			padding-left: 2rem;
			.add-name {
				font-size: .8125rem;
			}
			.add-tel {
				span.tel {
					margin-right: 0;
				}
				span.fax {
					display: block;
				}
			}
		}
		// .info-sns {
		// 	padding-top: 1.75rem;
		// 	ul {

		// 	}
		// }
		.info-sns {
			padding-top: 1rem;
			padding-bottom: 1rem;
			ul {
				padding-left: 0;
				max-width: 140px;
				margin: 0 auto;
				justify-content: center;
				li {
					width: 35px;
					padding: 0 2px;
					display: inline-block;
					img {
						width: 31px;
					}
				}
			}
		}
	}

}


@media (min-width: 992px) and (max-width: 1199px) {
	.navbar {
		#header-nav {
			min-height: 128px;
			width: 100%;
			.navbar-nav {
				width: 100%;
				flex-basis: 100%;
				justify-content: space-around;
			}
		}
		.navbar-brand {
			position: absolute;
			top: 10px;
			left: 50%;
			right: 50%;
			margin-left: -158px;
			height: 68px;
			z-index: 800;
		}
	}


	.navbar {
		padding-top: 0;
	}

	ul.navbar-sub {
		position: static;
		// top: 74px;
		// left: 0;
		// right: auto;
		position: absolute;
		top: 127px;
		z-index: 1000;
		width: 100%;
		.sub-item {
			width: 50%;
		}
		.sub-link {
			padding: 0 0;
			display: inline-block;
			text-align: center;
			width: 100%;
		}
	}
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	body {
		padding-top: 138px;
	}
	.navbar {
		> .container {
			flex-direction: row;
		}
		.navbar-brand {
			margin-top: 10px;
			height: 88px;
		}
		.navbar-collapse {
			.navbar-nav {
				margin-top: 0;
				.nav-link {
					padding: 0 1.25rem 1.25rem;
				}
			}
		}
	}

	.customer-cases {
		.cases {
			.case {
				.case-meta {
					padding-left: .25rem;
					padding-right: .25rem;
				}
			}
		}
	}

	.btn-lg.btn-btm-link {
		line-height: 70px;
		font-size: 1.15rem;
	}

	dl.row.no-gutters.detail-list {
		dt {
			text-align: left;
			border-right: none;
			border-left: 5px solid #69694f;
			margin-bottom: 1rem;
			padding-right: 0;
			padding-left: 1rem;
		}
		dd {
			margin-bottom: 1rem;
			padding-left: 1.25rem;
			font-size: 1rem;
			ul {
				padding-left: 0;
				margin-bottom: 0;
				li {
					padding-left: 0;
					list-style: none;
				}
			}
		}
	}

	.module-choices {
		.unit-title {
			h2 {
				font-size: 1.25rem;
			}
		}
		.choices {
			.choice {
				padding-left: .25rem;
				padding-right: .25rem;
				img {

				}
			h3 {
				font-size: 1rem;
				color: #F58700;
			}
			p {

				font-size: .8125rem;
				span {
					display: block;
				}
			}
			}
		}
	}

	.btn-pdflink {
		width: auto;
		margin: 0 auto;
		line-height: 44px;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.bt-wrap-t3 {
		margin: 0 auto;
		width: auto;
		// padding-left: 2rem;
		// padding-right: 2rem;
	}

}



@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) {

}

@media (min-width: 576px) and (max-width: 767px) {

}

// Extra small devices (portrait phones, less than 767px)
@media (max-width: 767px) {
	body {
		padding-top: 128px;
	}
	.navbar {
		.navbar-brand {
			height: 78px;
			min-width: 250px;
			background: url(../img/common/header_logo.png) 0 5px no-repeat;
			background-size: 250px auto;
		}
	}
	.carousel {
		margin-bottom: 2rem;
	}
	.carousel-item {
		.slidecaption {
			display: none;
			img {
				height: auto;
			}
		}
	}

	.page-hero {
		.heroimage {
			margin-bottom: -39px;
		}
		// margin-bottom: 1rem;
	}


	.page-title {
		background: #fff;
		border-radius: 1rem 1rem 0 0 / 1rem 1rem 0 0;
		width: 240px;
		height: 40px;
		.title-wrapper {
			h2 {
				font-size: 1rem;
				padding-bottom: 0;
			}
		}
	}

	// adjusted at 2017-12-11
	// .page-lead {
	// 	padding-bottom: 3.75rem;
	// 	.tagline {
	// 		p {
	// 			font-size: .875rem;
	// 		}
	// 	}
	// }
	// .top-lead {
	// 	font-size: 1.25rem;
	// }
	// .top-lead {
	// 	p {
	// 		font-size: 1.25rem;
	// 		margin-bottom: .15rem;
	// 	}
	// }

// adjusted at 2017-12-12
	.bt-wrap-t2 {
		padding-left: .5rem;
		padding-right: .5rem;
		a {
			font-size: .875rem;
		}
	}

	.btn-plink {
		font-size: .875rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}



	.customer-cases {
		.unit-title {
			h2 {
				line-height: 1.4;
				span {
					display: block;
				}
			}
		}
		.cases {
			.case {
				margin-bottom: 2rem;
				.case-meta {
					padding-left: 1rem;
					padding-right: 1rem;
					h3 {
						padding-top: .75rem;
					}
				}
			}
		}
	}

	.unit-title.bg-line {
		.title-wrapper {
			max-width: 1170px;
			margin-left: 0;
			margin-right: 0;
		}
		margin-bottom: 1rem;
		h3 {
			font-size: 1.25rem;
			span {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
	}

	.bottom-links {
		.unit-title {
			background: url(../img/common/bg_links_title.png) top center no-repeat;
			background-size: 96%;
		}
	}

	.btn-lg.btn-btm-link {
		line-height: 52px;
		// font-size: 1rem;
		img {
			display: none;
		}
	}

	.footer {
		font-size: .875rem;
		.col-11 {
			.col-4 {
				span {
					line-height: auto;
					display: inline;
					padding-left: 0;
				}
			&.link-faq {
				span {
					background: none;
				}
			}
			&.link-voice {
				span {
					background: none;
				}
			}
			&.link-consult {
				span {
					background: none;
				}
			}
			}
		}
	}
	.info-balloon,
	.info-balloon2 {
		.balloon1 {
			margin-left: 0;
			p.info-time {
				span {
					display: block;
					margin-right: 0;
				}
			}
		}
	}
	.balloon1:after {
		border: none;
	}

	.white-balloon {
		.balloon2 {
			margin-left: 0;
		}
	}
	.balloon2:after {
		border: none;
	}

	.module-choices {
		.choices {
			.choice {
				margin-bottom: 1.5rem;
			h3 {
				padding-top: .75rem;
			}
			p {
				font-size: .875rem;
			}
			}
		}
	}

	dl.evenodd {
		margin-bottom: 2.5rem;
		dt {
			font-weight: bold;
			padding-top: 1.25rem;
			padding-bottom: .25rem;
			span {
				display: inline;
			}
		&:nth-of-type(2n) {
			border-top: 1px solid #f58700;
			border-bottom: none;
		}
		}
		dd {
			font-size: .875rem;
			margin: 0;
			padding-top: 0;
			padding-bottom: 1rem;
			p {
				font-size: .875rem;
				span {
					display: inline;
				}
			}
		&:nth-of-type(2n) {
			border-top: none;
			border-bottom: 1px solid #f58700;
		}
		}
	}

}